import React from "react";
import Layout from "../../components/Layout";
import { numberFormatter } from "../../helper";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  getRealisasiBelanjaPerOPD,
  getGrafikSimbangda,
  getRealisasiBelanjaPerOPD_bybulan,
} from "../../../redux/ducks/home/actions";
import RankingOpdByKeuangan from "../../components/RankingOpdByKeuangan";
import RankingOpdByDeviasi from "../../components/RankingOpdByDeviasi";
import RankingOpdByFisik from "../../components/RankingOpdByFisik";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "./highcharts.css";

import Moment from "react-moment";

import CountUp from "react-countup";
import BackButton from "../../components/BackButton";

const DetailRealisasiBelanjaOpd = (props) => {
  const [colorTr, setColorTr] = React.useState(null);
  const [filterData, setFilterData] = React.useState("");
  const [popUpLogin, setPopUpLogin] = React.useState(false);
  const [valueSelect, setValueSelect] = React.useState("keuangan");
  const { yearconfig } = useParams()
  const [valueSelectBulan, setValueSelectBulan] = React.useState(
    new Date().getMonth() + 1
  );

  React.useEffect(() => {
    props.getGrafikSimbangda("rekap", yearconfig);
  }, []);

  React.useEffect(() => {
    const thisMonth = new Date().getMonth() + 1;
    console.log(valueSelectBulan, ' and ', thisMonth);
    if (valueSelectBulan === thisMonth) {
      props.getDataOpd(yearconfig);
    } else {
      props.getDataOpd_byBulan(valueSelectBulan, yearconfig);
    }
  }, [valueSelectBulan,yearconfig]);

  const changeMonth = (e) => {
    setValueSelectBulan(e.target.value);
  };

  const breadCrumbsData = [
    {
      title: "Home",
      isActive: true,
      goTo: "/tahun/"+yearconfig,
    },
    {
      title: "Detail Realisasi Belanja Opd",
      isActive: false,
      goTo: "",
    },
  ];

  /**
   * TODO CLEAN CODE FOR HIGHCAHRT
   */
  const options2 = {
    chart: {
      type: "column",
      styledMode: true,
    },

    title: {
      text: "Pencapaian Total SKPD",
      align: "center",
    },

    subtitle: {
      text: "Berdasarkan Akumulasi",
      align: "center",
    },

    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Des",
      ],
    },

    yAxis: [
      {
        // Primary axis
        className: "highcharts-color-0",
        title: {
          text: "Fisik",
        },
        labels: {
          enabled: true,
          formatter: function () {
            // return ``;
            return `${this.value}%`;
          },
        },
      },
      {
        // Secondary axis
        className: "highcharts-color-1",
        opposite: true,
        title: {
          text: "Keuangan",
        },
        labels: {
          enabled: true,
          formatter: function () {
            // return ``;
            return `${this.value}%`;
          },
        },
      },
    ],
    accessibility: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointWidth: 20,
      },
    },
    series: [
      {
        name: "Target Fisik",
        data: props.grafikData?.grafikBarTargetFisik,
        tooltip: {
          valueSuffix: "%",
        },
        yAxis: 0,
      },
      {
        type: "line",
        name: "Realisasi Fisik",
        data: props.grafikData?.grafikSplineRealisasiFisik,
        className: "spline-color-1",
        marker: {
          lineWidth: 2,
          lineColor: Highcharts.getOptions().colors[2],
          fillColor: "white",
        },
        yAxis: 0,
        tooltip: {
          valueSuffix: "%",
        },
      },
      {
        name: "Target Keuangan",
        data: props.grafikData?.grafikBarTargetKeuangan,
        yAxis: 1,
        tooltip: {
          valueSuffix: "%",
        },
      },
      {
        type: "spline",
        name: "Realisasi Keuangan",
        className: "spline-color-2",
        data: props.grafikData?.grafikSplineRealisasiKeuangan,
        marker: {
          lineWidth: 2,
          lineColor: Highcharts.getOptions().colors[2],
          fillColor: "white",
        },
        yAxis: 1,
        tooltip: {
          valueSuffix: "%",
        },
      },
    ],
  };

  const sortBy = (type = "") => {
    let sorted = null;
    switch (type) {
      case "keuangan":
        sorted = [...props.dataOpd].sort(
          (a, b) => b.persen_realisasi_keuangan - a.persen_realisasi_keuangan
        );
        break;
      case "deviasi":
        sorted = [...props.dataOpd].sort(
          (a, b) => b.deviasi_realisasi_keuangan - a.deviasi_realisasi_keuangan
        );
        break;
      case "fisik":
        sorted = [...props.dataOpd].sort(
          (a, b) => b.realisasi_fisik_akumulasi - a.realisasi_fisik_akumulasi
        );
        break;
      default:
        sorted = props.dataOpd;
    }
    return sorted.map((v, i) => ({
      ...v,
      urutan: i + 1,
    }));
  };

  const searchByOpd = () => {
    if (filterData == "") {
      return sortBy(valueSelect);
    }
    return sortBy(valueSelect).filter((items) =>
      items.skpd.toLowerCase().includes(filterData.toLowerCase())
    );
  };

  const totalPagu = () => {
    if (filterData == "") {
      return props.dataOpd.reduce(
        (prevValue, currentValue) => prevValue + parseInt(currentValue.pagu),
        0
      );
    }
    let temp = props.dataOpd.filter((items) =>
      items.skpd.toLowerCase().includes(filterData.toLowerCase())
    );
    return temp.reduce(
      (prevValue, currentValue) => prevValue + parseInt(currentValue.pagu),
      0
    );
  };

  const totalTargetKeuangan = () => {
    if (filterData == "") {
      return props.dataOpd.reduce(
        (prevValue, currentValue) =>
          prevValue + parseInt(currentValue.rp_target_keuangan),
        0
      );
    }
    let temp = props.dataOpd.filter((items) =>
      items.skpd.toLowerCase().includes(filterData.toLowerCase())
    );
    return temp.reduce(
      (prevValue, currentValue) =>
        prevValue + parseInt(currentValue.rp_target_keuangan),
      0
    );
  };

  const totalRealisasiKeuangan = () => {
    if (filterData == "") {
      return props.dataOpd.reduce(
        (prevValue, currentValue) =>
          prevValue + parseInt(currentValue.rp_realisasi_keuangan),
        0
      );
    }
    let temp = props.dataOpd.filter((items) =>
      items.skpd.toLowerCase().includes(filterData.toLowerCase())
    );
    return temp.reduce(
      (prevValue, currentValue) =>
        prevValue + parseInt(currentValue.rp_realisasi_keuangan),
      0
    );
  };

  const _showPopUpLogin = (items) => {
    // if (props.isLogin) {
    return (
      <Link
        to={`/tahun/${yearconfig}/simbangda/detail-kegiatan-opd/${items.skpd}/${items.detail.id_instansi}`}
        className="text-blue-500 underline text-left font-bold"
      >
        {items.skpd}
      </Link>
    );
    // } else {
    //   return (
    //     <button onClick={() => setPopUpLogin(true)} className="text-blue-500 underline text-left font-bold">
    //       {items.skpd}
    //     </button>
    //   );
    // }
  };
  const months = [
    "Semua",
    "Januari",
    "Febuari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];
  const changeRank = (e) => {
    setValueSelect(e.target.value);
  };
  const closeModalLogin = () => setPopUpLogin(false);
  console.log();
  return (
    <Layout
      loading={props.loading}
      breadCrumbsData={breadCrumbsData}
      popUpShow={popUpLogin}
      closePopUp={closeModalLogin}
    >
      <div className="bg-white py-3 px-5 rounded-md animate__animated animate__fadeIn">
        <div className="flex lg:flex-row flex-col justify-between mb-3">
          <div className="text-sm text-black font-medium flex flex-col lg:flex-row xl:flex-row pr-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-4 h-4 text-gray-500"
            >
              <path
                fillRule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-xs text-gray-600 pl-1">
              terakhir update : {props.timestamp}
            </span>
          </div>
          <div className="w-full lg:w-[30%] xl:w-[30%]">
            <label for="table-search" className="sr-only">
              Bulan
            </label>
            <div className="relative mt-1">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 text-gray-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                  />
                </svg>
              </div>
              <select
                className="bg-gray-50 border border-gray-300 text-gray-800 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5 placeholder:text-gray-900"
                value={valueSelectBulan}
                onChange={changeMonth}
              >
                {months.map((value, index) => (
                  <option value={index}>{value}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="grid grid-rows-1 lg:grid-cols-12 xl:grid-cols-12 gap-5 ">
          <div className="bg-white lg:col-span-2 col-span-5">
            <div className="float-center">
              <h1 className="font-bold text-primeColor1 text-center text-2xl">Belanja </h1>
              <img src="/img/pana.svg" className="w-full h-36 pl-5" alt="" />
            </div>
          </div>
          <div className="bg-white  rounded-xl col-span-5">
            <div className="border border-primeColor1 py-2 px-3 pb-2 rounded-lg">
              <span className="text-black font-bold ">
                Total APBD Tahun {yearconfig}
              </span>
              <br />
              <span className="text-primeColor1 text-2xl  font-bold">
                Rp.{" "}
                <CountUp
                  duration={1.75}
                  separator="."
                  end={props.pencapaianOpd.pagu}
                />
              </span>
              <p className="font-bold text-sm mt-2 mb-2">
                Target sampai dengan Bulan Ini
              </p>
              <h2 className="2xl:text-md xl:text-md text-sm text-black font-medium flex flex-col lg:flex-row xl:flex-row mb-1">
                <span className="w-[30%]">Fisik</span>
                <span>
                  :{" "}
                  {numberFormatter(props.pencapaianOpd.target_fisik_akumulasi)}{" "}
                  %
                </span>
              </h2>
              <h2 className="2xl:text-md xl:text-md text-sm text-black font-medium flex flex-col lg:flex-row xl:flex-row mb-1">
                <span className="w-[30%]">Keuangan</span>
                <span>
                  : Rp.{" "}
                  {numberFormatter(props.pencapaianOpd.rp_target_keuangan)} /{" "}
                  {numberFormatter(props.pencapaianOpd.persen_target_keuangan)} %
                </span>
              </h2>
            </div>
          </div>
          <div className="bg-white  rounded-xl col-span-5">
            <div className="border border-primeColor1 py-2 px-3 pb-2 rounded-lg">
              <span className="text-black  font-bold">
                Realisasi Keseluruhan
              </span>
              <br />
              <span className="text-primeColor1 text-2xl font-bold">
                {" "}
                Rp.{" "}
                <CountUp
                  duration={1.75}
                  separator="."
                  end={props.pencapaianOpd.rp_realisasi_keuangan}
                />
              </span>
              <p className="font-bold text-sm mt-2 mb-2">
                Realisasi sampai dengan Hari Ini
              </p>
              <h2 className="2xl:text-md xl:text-md text-sm text-black font-medium flex flex-col lg:flex-row xl:flex-row mb-1">
                <span className="w-[30%]">Fisik</span>
                <span>
                  :{" "}
                  {numberFormatter(
                    props.pencapaianOpd.realisasi_fisik_akumulasi
                  )}{" "}
                  %
                </span>
              </h2>
              <h2 className="2xl:text-md xl:text-md text-sm text-black font-medium flex flex-col lg:flex-row xl:flex-row mb-1">
                <span className="w-[30%]">Keuangan</span>
                <span>
                  : Rp.{" "}
                  {numberFormatter(props.pencapaianOpd.rp_realisasi_keuangan)} /{" "}
                  {numberFormatter(props.pencapaianOpd.persen_realisasi_keuangan)} %
                </span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white p-5 rounded-md mt-5">
        <HighchartsReact highcharts={Highcharts} options={options2} />
      </div>
      <RankingOpdByKeuangan data={sortBy("keuangan")} />
      <RankingOpdByDeviasi data={sortBy("deviasi")} />
      <RankingOpdByFisik data={sortBy("fisik")} />
      {/* <div className="w-full flex md:flex-row flex-col gap-5">
        <div className="bg-white p-5 rounded-md mt-5 md:w-2/3 w-full">
          <HighchartsReact highcharts={Highcharts} options={options2} />
        </div>
        <div className="bg-white p-5 rounded-md mt-5 md:w-1/3 h-60 w-full">
          <h1 className="text-xl font-bold text-center">
            5 OPD dengan Realisasi Keuangan terbaik Bulan ini
          </h1>
          <ol>
            <li>Kominfo</li>
            <li>Kominfo</li>
            <li>Kominfo</li>
            <li>Kominfo</li>
            <li>Kominfo</li>
          </ol>
        </div>
      </div> */}
      <div className="bg-white rounded-xl p-5 relative mt-5">
        <h1 className="text-center text-base mt-3">
          <span className="uppercase font-bold text-xl text-primeColor1">
            Laporan Realisasi Fisik & Keuangan Tahun {yearconfig}
          </span>
          <br />
          <span className="text-xs">
            Sampai Dengan{" "}
            <Moment format="D MMMM YYYY">{props.timestamp}</Moment>
          </span>
          {/* TODO : format date d MMMM YYYY */}
        </h1>
        {/* perubahan table disini sesuuaikan */}
        {/* <div className="p-2 flex flex-col md:flex-row lg:flex-row bg-gray-100 shadow-md mb-3 rounded-md">
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-orange-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z"
                clip-rule="evenodd"
              />
            </svg>
          </p>
          <p className="w-2"></p>
          <p className="text-xs text-gary-700 font-bold ">
            Untuk menampilkan detail program per opd silahkan klik nama opd. Data yang ditampilkan terakhir update{' '}
            <span className="font-bold text-red-500">{props.timestamp}</span>
          </p>
        </div> */}
        <div className="flex flex-col lg:flex-row xl:flex-row justify-between">
          <div className="py-2 w-full lg:w-[30%] xl:w-[30%]">
            <label for="table-search" className="sr-only">
              Search
            </label>
            <div className="relative mt-1">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-5 h-5 text-gray-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                onChange={(e) => setFilterData(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5 placeholder:text-gray-900 "
                placeholder="Cari berdasarkan nama OPD"
              />
            </div>
          </div>

          <div className="py-2 w-full lg:w-[30%] xl:w-[30%]">
            <label for="table-search" className="sr-only">
              Search
            </label>
            <div className="relative mt-1">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 text-gray-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                  />
                </svg>
              </div>
              <select
                value={valueSelect}
                className="bg-gray-50 border border-gray-300 text-gray-800 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5 placeholder:text-gray-900"
                onChange={changeRank}
              >
                <option value="keuangan">Keuangan</option>
                <option value="deviasi">Kinerja</option>
                <option value="fisik">Fisik</option>
              </select>
            </div>
          </div>
        </div>
        <table className="w-full table-auto text-xs">
          <thead className="bg-primeColor1 text-white sticky top-0">
            <tr>
              <th className=" border border-slate-500 " rowSpan={3}>
                No
              </th>
              <th className=" border border-slate-500 " rowSpan={3}>
                OPD
              </th>
              <th className=" border border-slate-500 " rowSpan={3}>
                PAGU
              </th>
              <th className=" border border-slate-500 p-2" colSpan={3}>
                FISIK
              </th>
              <th className=" border border-slate-500 p-2" colSpan={6}>
                KEUANGAN
              </th>
            </tr>
            <tr>
              <th className=" border border-slate-500" rowSpan={2}>
                TARGET
              </th>
              <th className=" border border-slate-500 p-1" rowSpan={2}>
                REALISASI
              </th>
              <th className=" border border-slate-500 p-1" rowSpan={2}>
                DEVIASI
              </th>
              <th className=" border border-slate-500" colSpan={2}>
                TARGET
              </th>
              <th className=" border border-slate-500 p-1" colSpan={2}>
                REALISASI
              </th>
              <th className=" border border-slate-500 p-1" colSpan={2}>
                DEVIASI
              </th>
            </tr>
            <tr>
              <th className="sticky top-0 border border-slate-500 p-1">Rp</th>
              <th className="sticky top-0 border border-slate-500 p-1">%</th>
              <th className="sticky top-0 border border-slate-500 p-1">Rp</th>
              <th className="sticky top-0 border border-slate-500 p-1">%</th>
              <th className="sticky top-0 border border-slate-500 p-1">%</th>
            </tr>
          </thead>
          <tbody>
            {searchByOpd().map((items, keys) => {
              return (
                <tr
                  key={keys}
                  onClick={() => setColorTr(keys)}
                  className={
                    (colorTr === keys ? "bg-blue-200" : "cursor-pointer") + " odd:bg-primeColor2"
                  }
                >
                  <td className="border border-slate-500 text-center w-12">
                    {items.urutan ? items.urutan : keys + 1}
                  </td>
                  <td className="border border-slate-500 py-3 px-2 w-[25%]">
                    {_showPopUpLogin(items)}
                  </td>
                  <td className="border border-slate-500 text-right font-bold p-2 w-[10%]">
                    {numberFormatter(items.pagu)}
                  </td>
                  <td className="border border-slate-500 text-center p-2 font-bold">
                    {" "}
                    {numberFormatter(items.target_fisik_akumulasi)}
                  </td>
                  <td className="border border-slate-500 text-center p-2 font-bold">
                    {numberFormatter(items.realisasi_fisik_akumulasi)}
                  </td>
                  <td className="border border-slate-500 text-center p-2 font-bold">
                    {numberFormatter(items.deviasi_fisik_akumulasi)}
                  </td>
                  <td className="border border-slate-500 text-right p-2 w-[10%] font-bold">
                    {numberFormatter(items.rp_target_keuangan)}
                  </td>
                  <td className="border border-slate-500 text-center p-2 font-bold">
                    {numberFormatter(items.persen_target_keuangan)}
                  </td>
                  <td className="border border-slate-500 text-right p-2 font-bold">
                    {numberFormatter(items.rp_realisasi_keuangan)}
                  </td>
                  <td className="border border-slate-500 text-center p-2 font-bold">
                    {numberFormatter(items.persen_realisasi_keuangan)}
                  </td>
                  <td className="border border-slate-500 text-center p-2 font-bold">
                    {numberFormatter(items.deviasi_realisasi_keuangan)}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr className="bg-primeColor1 text-white">
              <td
                colSpan={2}
                className="border border-slate-500 text-center p-2"
              ></td>
              <td className="border border-slate-500 text-right p-2 font-bold text-sm">
                {numberFormatter(totalPagu())}
              </td>
              <td
                className="border border-slate-500 text-center p-2"
                colSpan={3}
              ></td>
              <td className="border border-slate-500 text-right p-2 font-bold text-sm">
                {numberFormatter(totalTargetKeuangan())}
              </td>
              <td className="border border-slate-500 text-center p-2"></td>
              <td className="border border-slate-500 text-right p-2 font-bold text-sm">
                {numberFormatter(totalRealisasiKeuangan())}
              </td>
              <td
                className="border border-slate-500 text-center p-2"
                colSpan={2}
              ></td>
            </tr>
          </tfoot>
        </table>
      </div>
      <BackButton backLink={'/'} />
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    dataOpd: state.homeState.relisasiPerOpd.data,
    pencapaianOpd: state.homeState.relisasiPerOpd.pencapaian_opd,
    loading: state.homeState.relisasiPerOpd.loadingGet,
    error: state.homeState.relisasiPerOpd.errorGet,
    totalFooter: state.homeState.relisasiPerOpd.totalFooter,
    timestamp: state.homeState.relisasiPerOpd.timestamp,
    grafikData: state.homeState.grafikSimbangda.data,
    isLogin: state.authState.isLogin,
  };
};

const mapDispatchToProps = {
  getDataOpd: getRealisasiBelanjaPerOPD,
  getDataOpd_byBulan: getRealisasiBelanjaPerOPD_bybulan,
  getGrafikSimbangda,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(DetailRealisasiBelanjaOpd);
