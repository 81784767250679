import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getCapaianKinerjaGubernur_act } from '../../../redux/ducks/cpkgubernur/actions'
import Layout from '../../components/Layout'
import { useParams } from 'react-router-dom'
import BackButton from '../../components/BackButton'
import { numberFormatter } from '../../helper';

const htmlRemover = (string) => {
    const regex = /(<([^>]+)>)/gi;
    const result = string.replace(regex, "");
    return result
}

const CapaianKinerjaGubernur = (props) => {
    const { yearconfig } = useParams()
    const dataBreadcrumb = [
        {
            title: 'Home',
            isActive: true,
            goTo: '/tahun/'+yearconfig,
        },
        {
            title: 'Capaian PK Gubernur',
            isActive: false,
            goTo: '',
        }
    ]
    const triwulan = ["tw1", "tw2", "tw3", "tw4"]
    React.useEffect(() => {
        props.getCapaianKinerjaGubernur(yearconfig)
    }, [yearconfig])
    return (
        <Layout 
            loading={props.CapaianKinerjaGubernur.loadingGet}
            dataBreadcrumb={dataBreadcrumb}
        >
            <div className='bg-white rounded-lg drop-shadow-2xl py-5 px-2 w-full'>
                <div className="flex-grow bg-white rounded-xl py-2 relative">
                    <h1 className="mb-1 text-2xl font-bold text-center text-primeColor1">Capaian Perjanjian Kinerja Gubernur</h1>
                    <h1 className="mb-1 text-md font-bold text-center text-secondaryColor1">
                        Tahun {yearconfig}
                    </h1>
                    <hr className="mb-3" />
                </div>
                
                <div className="w-full sm:overflow-visible overflow-x-auto flex flex-col justify-center">
                    <table className="w-full md:w-2/3 table-auto text-xs mb-4 mx-auto">
                        <thead className="bg-primeColor1 text-white sticky top-0">
                            <tr>
                                <th className="border border-slate-500  w-[1%]" rowSpan={2}>
                                    No
                                </th>
                                <th className="border border-slate-500 md:w-2/5" rowSpan={2}>
                                    Sasaran
                                </th>
                                <th className="border border-slate-500 w-[30%]" rowSpan={2}>
                                    Indikator
                                </th>
                                <th className="border border-slate-500 md:w-[5%]" rowSpan={2}>
                                    Satuan
                                </th>
                                <th className="border border-slate-500" colSpan={3}>
                                    <span>Capaian Perjanjian Kinerja Gubernur</span>
                                </th>
                                {
                                    // triwulan.map((item, index) => {
                                    //     return (
                                    //         <th className="border border-slate-500" colSpan={3} key={index}>
                                    //             <span>{`Triwulan ${index+1}`}</span>
                                    //         </th>
                                    //     )
                                    // })
                                }
                            </tr>
                            <tr>
                                <th className="border border-slate-500">
                                    <span className='hidden md:inline'>Target</span>
                                    <span className='inline md:hidden'>T</span>
                                </th>
                                <th className="border border-slate-500">
                                    <span className='hidden md:inline'>Realisasi</span>
                                    <span className='inline md:hidden'>R</span>
                                </th>
                                <th className="border border-slate-500">
                                    <span className='hidden md:inline'>Capaian</span>
                                    <span className='inline md:hidden'>C</span>
                                </th>
                                {
                                    // triwulan.map((item, index) => {
                                    //     return (
                                    //         <>
                                    //             <th className="border border-slate-500" key={index}>
                                    //                 <span className='hidden md:inline'>Target</span>
                                    //                 <span className='inline md:hidden'>T</span>
                                    //             </th>
                                    //             <th className="border border-slate-500" key={index}>
                                    //                 <span className='hidden md:inline'>Realisasi</span>
                                    //                 <span className='inline md:hidden'>R</span>
                                    //             </th>
                                    //             <th className="border border-slate-500" key={index}>
                                    //                 <span className='hidden md:inline'>Capaian</span>
                                    //                 <span className='inline md:hidden'>C</span>
                                    //             </th>
                                    //         </>
                                    //     )
                                    // })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.CapaianKinerjaGubernur.data ? props.CapaianKinerjaGubernur.data.map((item, index) => {
                                    return (
                                        <>
                                            {item.indikator.map((indikator, indIndex) => (
                                                <tr className='odd:bg-primeColor2' key={indIndex}>
                                                    {indIndex === 0 && (
                                                        <>
                                                            <td className="border border-slate-500 bg-secondaryColor2 p-2 text-right align-top" rowSpan={item.indikator.length}>{index + 1}</td>
                                                            <td className="border border-slate-500 bg-secondaryColor2 p-2 align-top" rowSpan={item.indikator.length}>{item.nm_sasaran}</td>
                                                        </>
                                                    )}
                                                    <td className="border border-slate-500 p-2">{indikator.nm_sasaran_indikator}</td>
                                                    <td className="border border-slate-500 p-2 text-center">{htmlRemover(indikator.satuan)}</td>
                                                    {/* <td className="border border-slate-500 p-2 text-right">{indikator.target_tw1}</td>
                                                    <td className="border border-slate-500 p-2 text-right">{indikator.realisasi_tw1}</td>
                                                    <td className="border border-slate-500 p-2 text-right">{indikator.capaian_tw1}</td>
                                                    <td className="border border-slate-500 p-2 text-right">{indikator.target_tw2}</td>
                                                    <td className="border border-slate-500 p-2 text-right">{indikator.realisasi_tw2}</td>
                                                    <td className="border border-slate-500 p-2 text-right">{indikator.capaian_tw2}</td>
                                                    <td className="border border-slate-500 p-2 text-right">{indikator.target_tw3}</td>
                                                    <td className="border border-slate-500 p-2 text-right">{indikator.realisasi_tw3}</td>
                                                    <td className="border border-slate-500 p-2 text-right">{indikator.capaian_tw3}</td> */}
                                                    <td className="border border-slate-500 p-2 text-right">{numberFormatter(indikator.target_tw4)}</td>
                                                    <td className="border border-slate-500 p-2 text-right">{indikator.realisasi_tw4 !== "" ? numberFormatter(indikator.realisasi_tw4) : '0'}</td>
                                                    <td className="border border-slate-500 p-2 text-right">{numberFormatter(indikator.capaian_tw4)}</td>
                                                </tr>
                                            ))}
                                        </>
                                    );
                                }) :
                                <tr>
                                    <td className="border border-slate-500 py-2 px-5 bg-green-200" colSpan={13}>Loading...</td>
                                </tr>
                            }
                    
                        </tbody>
                    </table>
                    {/* <span className="text-red-400 text-xs font-bold">
                        Notes : <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; *) Data triwulan IV
                    </span> */}
                </div>
                <div className="w-full px-10">
                </div>
            </div>
            <BackButton backLink={`/tahun/${yearconfig}`} />
        </Layout>
    )
}

const mapStateToProps = (state) => ({
    CapaianKinerjaGubernur: state.cpkgubernurState.CapaianKinerjaGubernur
})

const mapDispatchToProps = {
    getCapaianKinerjaGubernur: getCapaianKinerjaGubernur_act
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, React.memo)(CapaianKinerjaGubernur)

