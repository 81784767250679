import React, { useEffect, memo, useState } from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import Layout from '../../../components/Layout';
import { getIku_act } from "../../../../redux/ducks/iku/actions"
import BackButton from '../../../components/BackButton';
import { useParams } from 'react-router-dom';

const tahun = [2021,2022,2023,2024,2025,2026]
const DataIndikatorKinerjaUtama = (props) => {
    const { yearconfig } = useParams()
    const initBreadcrumbs = [
        {
          title: 'Home',
          isActive: true,
          goTo: '/tahun/'+yearconfig,
        },
        {
          title: `Indikator Kinerja Utama`,
          isActive: false,
          goTo: '',
        },
    ];
    const [breadCrumbsData, setBreadCrumbsData] = useState();
    useEffect(() => {
        setBreadCrumbsData(initBreadcrumbs)
    },[])
    useEffect(() => {
        props.getIku()
    },[])
    return (
        <Layout
            loading={props.dataIku.loadingGet}
            breadCrumbsData={breadCrumbsData}
        >
          <div
              className="bg-white rounded-lg drop-shadow-2xl py-5 px-2 w-full"
          >
              <div className="flex-grow bg-white rounded-xl py-2 relative">
                  <h3 className="text-lg font-bold text-primeColor1 text-center">Indikator Kinerja Utama</h3>
                  <hr className="mb-3" />
              </div>
              
              <div className="w-full flex flex-col justify-center sm:overflow-visible overflow-x-auto">
                    <table className="w-full table-auto text-xs mb-4">
                        <thead className="bg-primeColor1 text-white sticky top-0">
                            <tr>
                                <th className="border border-slate-500 md:w-[1%]" rowSpan={3}>
                                    No
                                </th>
                                <th className="border border-slate-500 md:w-1/4" rowSpan={3}>
                                    Indikator
                                </th>
                                <th className="border border-slate-500 md:w-1/12" rowSpan={3}>
                                    Satuan
                                </th>
                                <th className="border border-slate-500" colSpan={tahun.length * 2}>
                                    Tahun
                                </th>
                            </tr>
                            <tr>
                                {
                                    tahun.map((item) => {
                                        return (
                                            <th className="border border-slate-500" colSpan={2}>{item}</th>
                                        )
                                    })
                                }
                            </tr>
                            <tr>
                                {
                                    tahun.map((item) => {
                                        return (
                                            <>
                                                <th className="border border-slate-500">
                                                    <span className="md:block hidden">Target</span>
                                                    <span className="md:hidden block">T</span>
                                                </th>
                                                <th className="border border-slate-500">
                                                    <span className="md:block hidden">Realisasi</span>
                                                    <span className="md:hidden block">R</span>
                                                </th>
                                            </>
                                        )
                                    })
                                }
                            </tr>
                            
                        </thead>
                        <tbody>
                            {
                                props.dataIku ? props.dataIku.data.map((item, keys) => {
                                    const indikator = renderIndikator(item.iku)
                                    const misi = (
                                        <>
                                            <tr key={keys}>
                                                <td className="border border-slate-500 py-3 px-5 bg-primeColor2 text-primeColor1 font-bold text-md" colSpan="100%">{item.misi}</td>
                                            </tr>
                                            {
                                                indikator
                                            }
                                        </>
                                    )
                                    return (misi)
                                }) : 
                                <tr>
                                    <td className="border border-slate-500 py-2 px-5 bg-green-200" colSpan="100%">Loading...</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    {/* <span className="text-red-400 text-xs font-bold">
                        Notes : <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; *) Data triwulan IV
                    </span> */}
                </div>
          </div>
            <BackButton backLink={'/tahun/'+yearconfig} />
        </Layout>
    )
}

const renderIndikator = (list) => {
    return list?.map((val, keys) => {
        return (
            <tr className='odd:bg-secondaryColor2' key={keys}>
                <td className="border border-slate-500 p-2 text-center">{keys+1}</td>
                <td className={`border border-slate-500 py-2 pr-2 ` + (val.tabs ? `pl-${(val.tabs*10)}` : `pl-2`) }>{val.indikator}</td>
                <td className="border border-slate-500 p-2 text-center">{val.satuan}</td>
                {
                    tahun.map((item) => {
                        return (
                            <>
                                <td className="border border-slate-500 p-2 text-center">{val[`t_${item}`]}</td>
                                <td className="border border-slate-500 p-2 text-center">{val[`r_${item}`]}</td>
                            </>
                        )
                    })
                }
            </tr>
        )
    })
}

const mapStateToProps = (state) => ({
    dataIku: state.ikuState.dataIku
})

const mapDispatchToProps = {
    getIku: getIku_act
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, memo)(DataIndikatorKinerjaUtama)