import React from 'react'
import Layout from '../../components/Layout'
import BackButton from '../../components/BackButton'

const HotlinePage = () => {
  const dataBreadcrumb = [
      {
          title: 'Home',
          isActive: true,
          goTo: '/',
      },
      {
          title: 'Hotline Pengaduan',
          isActive: false,
          goTo: '',
      }
  ]



  return (
    <Layout
      loading={false}
      dataBreadcrumb={dataBreadcrumb}
    >
      
      <div
              className="bg-white rounded-lg drop-shadow-2xl py-5 px-2 w-full"
          >
              <div className="flex-grow bg-white rounded-xl py-2 relative">
                  <h3 className="text-lg text-primeColor1 font-bold text-center">Hubungi Kami</h3>
                  <hr className="mb-3" />
              </div>
              <div className="min-w-full px-4 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 place-content-center gap-5">

                  {
                    listHotline.map((item, index) => (
                      <div className="w-full bg-gray-50 drop-shadow-lg border border-primeColor1 rounded-lg">
                        <div className="py-3 px-4 flex flex-col gap-2 relative">
                            <div className="w-full rounded-t-lg  border-primeColor1 bg-primeColor1 py-2 px-3 absolute top-0 left-0">
                              <p className="font-bold md:text-lg text-md text-white">{item.title}</p>
                            </div>
                            <div className="w-full md:mt-12 mt-10 rounded-md border py-2 px-3 overflow-scroll flex flex-row gap-3 items-center">
                              <div className="p-2 bg-primeColor1 rounded-full text-white">
                                <svg data-slot="icon" fill="none" strokeWidth={2} stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"  className="h-5 w-5">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z"></path>
                                </svg>
                              </div>
                              <p className="md:text-sm text-xs">{item.instance}</p>
                            </div>
                            <div className="w-full rounded-md bg-primeColor2 py-2 px-3 overflow-scroll flex flex-row gap-3 items-center">
                              <div className="p-2 bg-primeColor1 rounded-full text-white">
                                <svg dataSlot="icon" fill="none" strokeWidth={2} stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className='h-5 w-5'>
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z" />
                                </svg>
                              </div>
                              <p className="md:text-sm text-xs">{item.address}</p>
                            </div>
                            <div className="w-full rounded-md border py-2 px-3 overflow-scroll flex flex-row gap-3 items-center">
                              <div className="p-2 bg-primeColor1 rounded-full text-white">
                                <svg dataSlot="icon" fill="none" strokeWidth={2} stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className='h-5 w-5'>
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                                </svg>
                              </div>
                              <p className="md:text-sm text-xs">{item.email}</p>
                            </div>
                            <a href={item.link} target='_blank' className="w-full rounded-md bg-primeColor2 py-2 px-3 overflow-scroll flex flex-row gap-3 items-center">
                              <div className="p-2 bg-primeColor1 rounded-full text-white">
                                <svg dataSlot="icon" fill="none" strokeWidth={2} stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className='h-5 w-5'>
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                                </svg>
                              </div>
                              <p className="md:text-sm text-xs">{item.website}</p>
                            </a>
                        </div>
                    </div>
                    ))
                  }
                  
              </div>
      </div>
        <BackButton  backLink={`/`}  />
    </Layout>
  )
}

const listHotline = [
  {
    title: "Pendapatan Daerah",
    instance: "Badan Pendapatan Daerah Provinsi Sumatera barat",
    address: "Jln. Khatib Sulaiman No.43 A Padang",
    email: "bapenda@sumbarprov.go.id",
    website: "bapenda.sumbarprov.go.id",
    link: "https://bapenda.sumbarprov.go.id/"
  },
  {
    title: "Realisasi Belanja Perangkat Daerah",
    instance: "Biro Administrasi Pembangunan Provinsi Sumatera barat",
    address: "Komplek Kantor Gubernur Jl. Jend. Sudirman No.51, Padang Pasir, Kec. Padang Barat, Kota Padang, Sumatera Barat 25129",
    email: "biro.adpembangunan@sumbarprov.go.id",
    website: "biroadmpembangunan.sumbarprov.go.id",
    link: "https://biroadmpembangunan.sumbarprov.go.id/"
  },
  {
    title: "Pengadaan Barang dan Jasa",
    instance: "Biro Pengadaan Barang dan Jasa Provinsi Sumatera barat",
    address: "Komplek Kantor Gubernur Jl. Jend. Sudirman No.51, Padang Pasir, Kec. Padang Barat, Kota Padang, Sumatera Barat 25129",
    email: "biro.pbj@sumbarprov.go.id",
    website: "biropbj.sumbarprov.go.id",
    link: "https://biropbj.sumbarprov.go.id/"
  },
  {
    title: "Capaian Perjanjian Kinerja",
    instance: "Biro Organisasi Provinsi Sumatera barat",
    address: "Komplek Kantor Gubernur Jl. Jend. Sudirman No.51, Padang Pasir, Kec. Padang Barat, Kota Padang, Sumatera Barat 25129",
    email: "biro.organisasi@sumbarprov.go.id",
    website: "biroorganisasi.sumbarprov.go.id",
    link: "https://biroorganisasi.sumbarprov.go.id/"
  },
  {
    title: "Indikator Kinerja Utama Daerah dan Program",
    instance: "Badan Perencanaan Pembangunan Daerah Provinsi Sumatera barat",
    address: "Jln. Khatib Sulaiman no 1 Padang",
    email: "bappeda@sumbarprov.go.id",
    website: "bappeda.sumbarprov.go.id",
    link: "https://bappeda.sumbarprov.go.id/"
  }
]

export default HotlinePage