import React, { useEffect, memo, useState } from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import Layout from '../../../components/Layout';
import { getLayananIkd_act } from "../../../../redux/ducks/ikp/actions"
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon, PlusIcon } from '@heroicons/react/20/solid'
import { NavLink, useParams } from 'react-router-dom';
import BackButton from '../../../components/BackButton';

const LayananDanUrusan = (props) => {
    const { yearconfig } = useParams()
    useEffect(() => {
        props.getLayananIkd()
    },[])
    const breadCrumbsData = [
        {
          title: 'Home',
          isActive: true,
          goTo: '/tahun/'+yearconfig,
        },
        {
          title: `Indikator Kinerja Program`,
          isActive: false,
          goTo: '',
        },
    ];
    return (
        <Layout
            loading={props.layananIkp.loadingGet}
            breadCrumbsData={breadCrumbsData}
        >
          <div
              className="bg-white rounded-lg drop-shadow-2xl py-5 px-2 w-full"
          >
              <div className="flex-grow bg-white rounded-xl py-2 relative">
                  <h1 className="text-xl font-bold text-center text-primeColor1">Indikator Kinerja Program</h1>
                  <h1 className="mb-1 text-sm text-gray-400 text-center">
                      pilih Layanan dan Urusan di bawah untuk melihat indikator kinerja program
                  </h1>
                  <hr className="mb-3" />
              </div>
              <div className="w-full px-4 pt-2">
                  <div className="flex flex-col gap-2 mx-auto w-full max-w-3xl rounded-2xl bg-white p-2">
                    {
                        props.layananIkp.data.map((items) => {
                            return (
                                <Disclosure className="w-full ">
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button className="flex w-full justify-between items-center rounded-lg bg-primeColor2 border-l-8 border border-primeColor1 hover:border-secondaryColor1 sm:p-4 px-4 py-2 text-left text-sm font-medium hover:text-primeColor1 hover:bg-alternateColor2 focus:outline-none focus-visible:ring focus-visible:ring-green-500 focus-visible:ring-opacity-75">
                                              
                                              <div className="flex items-center justify-center sm:gap-4 gap-2">
                                                  <span 
                                                      className="rounded-full p-1 group-hover:bg-secondaryColor2 group-hover:text-primeColor1  bg-white text-green-500">
                                                      <PlusIcon
                                                        className={`${
                                                          open ? 'rotate-45 transform' : ''
                                                        } sm:h-5 h-3 sm:w-5 w-3 text-green-500`}
                                                      />


                                                  </span>
                                                  <span className='sm:text-sm text-xs'>{items.layanan}</span>
                                              </div>
                                              
                                            </Disclosure.Button>
                                            <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm bg-secondaryColor2 rounded-lg border border-l-8 border-primeColor1">
                                                <div className="flex flex-col gap-1">
                                                  {
                                                    items.data_urusan.map((urusans) =>{
                                                      return (
                                                        <NavLink to={`/tahun/${yearconfig}/ikp/data/${urusans.id_urusan}/${encodeURIComponent(urusans.urusan)}`}  className="py-2 hover:underline hover:text-blue-500 md:px-10 px-6 flex flex-row gap-2 text-xs" >
                                                            <ChevronUpIcon 
                                                              className="h-3 sm:h-5 w-3 sm:w-5 rotate-90 transform"
                                                            />
                                                            {urusans.urusan}
                                                        </NavLink>
                                                      )
                                                    })
                                                  }
                                                </div>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            )
                        })
                    }
                    
                    
                  </div>
              </div>
          </div>
          <BackButton backLink={'/tahun/'+yearconfig} />
        </Layout>
    )
}

const mapStateToProps = (state) => ({
    layananIkp: state.ikpState.layananIkp
})

const mapDispatchToProps = {
    getLayananIkd: getLayananIkd_act
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, memo)(LayananDanUrusan)