import React from 'react';
import Layout from '../../components/Layout';
import { numberFormatter } from '../../helper';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { WithIsPrivate } from '../../hoc';
import BackButton from '../../components/BackButton';

const DetailTablePendapatan = ({ tableData, last_update }) => {
  const navigate = useNavigate();
  const { type, name, yearconfig } = useParams();

  console.log(type);

  React.useEffect(() => {
    if (tableData.length === 0) {
      navigate(-1);
    }
  }, [tableData]);

  const _year = () => {
    let d = new Date();

    return {
      now: d.getFullYear(),
      before: d.getFullYear() - 1,
    };
  };

  function isUpperCase(str) {
    return str === str.toUpperCase();
  }

  const isBigParent = (text) => {
    if (isUpperCase(text)) {
      return 'font-bold px-1 py-3 bg-alternateColor2';
    }
    return 'px-5 py-2';
  };

  const filterDataTable = () => {
    if (type == 'all') {
      return tableData;
    }
    return tableData.filter((items) => items.kode.includes(type));
  };

  const ucWord = (text) => {
    return text.toLowerCase().replace(/\b[a-z]/g, function (letter) {
      return letter.toUpperCase();
    });
  };

  const breadCrumbsData = [
    {
      title: 'Home',
      isActive: true,
      goTo: '/tahun/'+yearconfig,
    },
    {
      title: 'Detail Pendapatan',
      isActive: true,
      goTo: `/tahun/${yearconfig}/bappenda/detail-pendapatan`,
    },
    {
      title: `Table ${ucWord(name)}`,
      isActive: false,
      goTo: '',
    },
  ];

  return (
    <Layout breadCrumbsData={breadCrumbsData}>
      <div className="bg-white rounded-xl p-5 relative">
        <h1 className="text-lg font-bold mb-3  text-center">TABEL {name.toUpperCase()}</h1>
        <div className="p-2 flex flex-col md:flex-row lg:flex-row bg-gray-200 shadow-md mb-3 rounded-md">
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-orange-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z"
                clip-rule="evenodd"
              />
            </svg>
          </p>
          <p className="w-2"></p>
          <p className="text-xs text-gary-700 font-bold ">
            data yang ditampilkan terakhir update <span className="text-red-500">{last_update}</span>
          </p>
        </div>
        <table className="w-full text-xs">
          <thead className="bg-primeColor1 sticky top-0 text-white">
            <tr>
              <th className="border border-slate-500 p-2" rowSpan={2}>
                No
              </th>
              <th className="border border-slate-500" rowSpan={2}>
                Jenis Pendapatan
              </th>
              {/* <th className="border border-slate-500 p-2" colSpan={2}>
                {_year().before}
              </th> */}
              <th className="border border-slate-500 p-2" colSpan={2}>
                {yearconfig}
              </th>
              <th className="border border-slate-500 p-2" colSpan={2}>
                +/-
              </th>
            </tr>
            <tr>
              {/* <th className="border border-slate-500 p-2">Rencana</th>
              <th className="border border-slate-500 p-2">Realisasi</th> */}
              <th className="border border-slate-500">Target</th>
              <th className="border border-slate-500">Realisasi</th>
              <th className="border border-slate-500">Rp</th>
              <th className="border border-slate-500">%</th>
            </tr>
          </thead>
          <tbody>
            {filterDataTable().map((items, keys) => {
              return items.kode.length == 7 ? (
                <tr key={keys}>
                  <td className={`border border-slate-500 text-center bg-primeColor2 px-2 py-5 font-bold`}>{items.kode}</td>
                  <td className={`border border-slate-500 text-left  bg-primeColor2 px-2 py-5 font-bold`}>{items.uraian}</td>
                  {/* <td className={`border border-slate-500 text-center bg-primeColor2 px-2 py-5 font-bold`}>
                    {items.target}
                  </td>
                  <td className={`border border-slate-500 text-center bg-primeColor2 px-2 py-5 font-bold`}>
                    {items.pendapatan}
                  </td> */}
                  <td className={`border border-slate-500 text-center bg-primeColor2 px-5 py-5 font-bold`}>
                    {numberFormatter(items.after[0]?.target)}
                  </td>
                  <td className={`border border-slate-500 text-center bg-primeColor2 px-2 py-5 font-bold`}>
                    {numberFormatter(items.after[0]?.pendapatan)}
                  </td>
                  <td className={`border border-slate-500 text-center bg-primeColor2 px-2 py-5 font-bold`}>
                    {numberFormatter(items.after[0]?.selisih)}
                  </td>
                  <td className={`border border-slate-500 text-center bg-primeColor2 px-2 py-5 font-bold`}>
                    {numberFormatter(items.after[0]?.persentase)}
                  </td>
                </tr>
              ) : (
                <tr key={keys}>
                  <td className={`border border-slate-500 text-center ${isBigParent(items.uraian)}`}>{items.kode}</td>
                  <td className={`border border-slate-500 text-left  ${isBigParent(items.uraian)}`}>{items.uraian}</td>
                  {/* <td className={`border border-slate-500 text-center ${isBigParent(items.uraian)}`}>{items.target}</td>
                  <td className={`border border-slate-500 text-center ${isBigParent(items.uraian)}`}>{items.pendapatan}</td> */}
                  <td className={`border border-slate-500 text-center ${isBigParent(items.uraian)}`}>
                    {numberFormatter(items.after[0]?.target)}
                  </td>
                  <td className={`border border-slate-500 text-center ${isBigParent(items.uraian)}`}>
                    {numberFormatter(items.after[0]?.pendapatan)}
                  </td>
                  <td className={`border border-slate-500 text-center ${isBigParent(items.uraian)}`}>
                    {numberFormatter(items.after[0]?.selisih)}
                  </td>
                  <td className={`border border-slate-500 text-center ${isBigParent(items.uraian)}`}>
                    {numberFormatter(items.after[0]?.persentase)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <BackButton backLink={`/tahun/${yearconfig}/bappenda/detail-pendapatan`} />
    </Layout>
  );
};

const mapStateToProps = (state) => {
  console.log('STATE CURRENT', state);
  return {
    tableData: state.homeState.allPendapatan.tableData,
    last_update: state.homeState.allPendapatan.last_update,
  };
};

const withConnect = connect(mapStateToProps);

export default withConnect(DetailTablePendapatan);
