import React from 'react';
import Layout from '../../components/Layout';
import { numberFormatter } from '../../helper';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getRealisasiKegiatanOPD, getGrafikSimbangda, getRealisasiBelanjaPerOPD } from '../../../redux/ducks/home/actions';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import './highcharts.css';
import { WithIsPrivate } from '../../hoc';
import CountUp from 'react-countup';

import Moment from 'react-moment';
import BackButton from '../../components/BackButton';

const DetailKegiatanOpd = (props) => {
  const [colorTr, setColorTr] = React.useState(null);
  const navigate = useNavigate();
  const { opdName, opdId, yearconfig } = useParams();
  const [filterData, setFilterData] = React.useState('');
  const [belanjaOpd, setbelanjaOpd] = React.useState({
    pagu: 0,
    rp_target_keuangan: 0,
    target_fisik_akumulasi: 0,
    persen_target_keuangan: 0,
    rp_realisasi_keuangan: 0,
    realisasi_fisik_akumulasi: 0,
    persen_realisasi_keuangan: 0
  });
  
  const breadCrumbsData = [
    {
      title: 'Home',
      isActive: true,
      goTo: '/tahun/'+yearconfig,
    },
    {
      title: 'Detail Realisasi Belanja Opd',
      isActive: true,
      goTo: `/tahun/${yearconfig}/simbangda/detail-belanja-opd`,
    },
    {
      title: `Detail Kegiatan`,
      isActive: false,
      goTo: '',
    },
  ];

  React.useEffect(() => {
    props.getRealisasiKegiatanOPD(opdId, yearconfig);
    props.getGrafikSimbangda(opdId, yearconfig);
    
  }, [opdId, yearconfig]);
  React.useEffect(() =>{
    
    if(props.dataOpdSeluruhnya.length === 0){
      navigate(-1)
    }
    console.log(filteredByOpdId()[0]);
    setbelanjaOpd(filteredByOpdId()[0])
  }, [])

  const searchByNamaProgram = () => {
    if (filterData == '') {
      return props.dataOpd;
    }
    return props.dataOpd.filter((items) => items.nama.toLowerCase().includes(filterData.toLowerCase()));
  };

  const filteredByOpdId = () => {
    return props.dataOpdSeluruhnya.filter((items) => {
      return items.detail.id_instansi === opdId
    })
  }

  /**
   * TODO CLEAN CODE FOR HIGHCAHRT
   */
  const options2 = {
    chart: {
      type: 'column',
      styledMode: true,
    },

    title: {
      text: `PENCAPAIAN ${opdName}`,
      align: 'center',
      className: 'text-primeColor1'
    },

    subtitle: {
      text: 'Berdasarkan Akumulasi',
      align: 'center',
    },

    xAxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Des'],
    },

    yAxis: [
      {
        // Primary axis
        className: 'highcharts-color-0',
        title: {
          text: 'Fisik',
        },
        labels: {
          enabled: true,
          formatter: function () {
            return `${this.value}%`;
          },
        },
      },
      {
        // Secondary axis
        className: 'highcharts-color-1',
        opposite: true,
        title: {
          text: 'Keuangan',
        },
        labels: {
          enabled: true,
          formatter: function () {
            return `${this.value}%`;
          },
        },
      },
    ],
    accessibility: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointWidth: 20,
      },
    },
    series: [
      {
        name: 'Target Fisik',
        data: props.grafikData?.grafikBarTargetFisik,
        tooltip: {
          valueSuffix: '%',
        },
        yAxis: 0,
      },
      {
        type: 'line',
        name: 'Realisasi Fisik',
        data: props.grafikData?.grafikSplineRealisasiFisik,
        className: 'spline-color-1',
        marker: {
          lineWidth: 2,
          lineColor: Highcharts.getOptions().colors[2],
          fillColor: 'white',
        },
        yAxis: 0,
        tooltip: {
          valueSuffix: '%',
        },
      },
      {
        name: 'Target Keuangan',
        data: props.grafikData?.grafikBarTargetKeuangan,
        yAxis: 1,
        tooltip: {
          valueSuffix: '%',
        },
      },
      {
        type: 'spline',
        name: 'Realisasi Keuangan',
        className: 'spline-color-2',
        data: props.grafikData?.grafikSplineRealisasiKeuangan,
        marker: {
          lineWidth: 2,
          lineColor: Highcharts.getOptions().colors[2],
          fillColor: 'white',
        },
        yAxis: 1,
        tooltip: {
          valueSuffix: '%',
        },
      },
      // {
      //   name: 'Target Fisik',
      //   data: props.grafikData?.grafikBarTargetFisik,
      //   tooltip: {
      //     valueSuffix: '',
      //   },
      // },
      // {
      //   name: 'Target Keuangan',
      //   data: props.grafikData?.grafikBarTargetKeuangan,
      //   yAxis: 1,
      // },
      // {
      //   type: 'spline',
      //   name: 'Realisasi Keuangan',
      //   className: 'spline-color-1',
      //   data: props.grafikData?.grafikSplineRealisasiKeuangan,
      //   marker: {
      //     lineWidth: 2,
      //     lineColor: Highcharts.getOptions().colors[1],
      //     fillColor: 'white',
      //   },
      // },
      // {
      //   type: 'spline',
      //   name: 'Realisasi Fisik',
      //   data: props.grafikData?.grafikSplineRealisasiFisik,
      //   className: 'spline-color-2',
      //   marker: {
      //     lineWidth: 2,
      //     lineColor: Highcharts.getOptions().colors[2],
      //     fillColor: 'white',
      //   },
      // },
    ],
  };

  return (
    <Layout loading={props.loading} breadCrumbsData={breadCrumbsData}>
      <div className="bg-white rounded-xl py-1 px-3 pb-3 relative mb-5">
        <div className=" rounded-lg mt-5">
          <HighchartsReact highcharts={Highcharts} options={options2} />
        </div>
      </div>
      <div className="bg-white rounded-xl py-1 px-3 pb-3 relative mb-5">
        <div className="bg-white py-3 px-5 rounded-md animate__animated animate__fadeIn">
          <div className="grid grid-rows-1 lg:grid-cols-12 xl:grid-cols-12 gap-5 ">
            <div className="bg-white  rounded-xl col-span-5">
              <div className="border border-primeColor1 bg-primeColor2 py-2 px-3 pb-2 rounded-lg">
                <span className="text-black font-bold ">Total Pagu Anggaran {yearconfig} <br /> {opdName}</span>
                <br />
                <span className="text-primeColor1 text-2xl  font-bold">
                  Rp. <CountUp duration={1.75} separator="." end={belanjaOpd.pagu} />
                </span>
                <p className="font-bold text-sm mt-2 mb-2">Target sampai dengan Bulan Ini</p>
                <h2 className="2xl:text-md xl:text-md text-sm text-black font-medium flex flex-col lg:flex-row xl:flex-row mb-1">
                  <span className="w-[30%]">Fisik</span>
                  <span>: {numberFormatter(belanjaOpd.target_fisik_akumulasi)} %</span>
                </h2>
                <h2 className="2xl:text-md xl:text-md text-sm text-black font-medium flex flex-col lg:flex-row xl:flex-row mb-1">
                  <span className="w-[30%]">Keuangan</span>
                  <span>
                    : Rp. {numberFormatter(belanjaOpd.rp_target_keuangan)} /{' '}
                    {numberFormatter(belanjaOpd.persen_target_keuangan)} %
                  </span>
                </h2>
              </div>
            </div>
            <div className="bg-white lg:col-span-2 col-span-5 pt-2">
              <div className="float-center">
                <h1 className="font-bold text-center text-primeColor1 text-xl mb-3">Belanja OPD</h1>
                <img src="/img/amico.svg" className="w-full h-36" alt="" />
              </div>
            </div>
            <div className="bg-white  rounded-xl col-span-5">
              <div className="border border-primeColor1 bg-primeColor2 py-2 px-3 pb-2 rounded-lg">
                <span className="text-black  font-bold">Realisasi Keseluruhan</span>
                <br />
                <span className="text-primeColor1 text-2xl font-bold">
                  {' '}
                  Rp. <CountUp duration={1.75} separator="." end={belanjaOpd.rp_realisasi_keuangan} />
                </span>
                <p className="font-bold text-sm mt-2 mb-2">Realisasi sampai dengan Hari Ini</p>
                <h2 className="2xl:text-md xl:text-md text-sm text-black font-medium flex flex-col lg:flex-row xl:flex-row mb-1">
                  <span className="w-[30%]">Fisik</span>
                  <span>: {numberFormatter(belanjaOpd.realisasi_fisik_akumulasi)} %</span>
                </h2>
                <h2 className="2xl:text-md xl:text-md text-sm text-black font-medium flex flex-col lg:flex-row xl:flex-row mb-1">
                  <span className="w-[30%]">Keuangan</span>
                  <span>
                    : Rp. {numberFormatter(belanjaOpd.rp_realisasi_keuangan)} /{' '}
                    {numberFormatter(belanjaOpd.persen_realisasi_keuangan)} %
                  </span>
                </h2>
                <h2 className="2xl:text-md xl:text-md text-sm text-black font-medium flex flex-col lg:flex-row xl:flex-row mb-1">
                  <span className="w-[30%]">Deviasi Fisik/ Keuangan </span>
                  <span>
                    : {numberFormatter(belanjaOpd.deviasi_fisik_akumulasi)} % / {numberFormatter(belanjaOpd.deviasi_realisasi_keuangan)} %
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>  
      <div className="bg-white rounded-xl py-1 px-3 pb-3 relative mb-5">
        <h1 className="text-center text-base mt-4">
          <span className="uppercase font-bold text-primeColor1">
            Laporan Realisasi Fisik & Keuangan Tahun {yearconfig} <br /> {opdName}
          </span>
        </h1>
        <p className="text-alternateColor1 text-sm text-center mb-3">
          <span>Sampai Dengan </span>
          {props.loading ? '' : <Moment format="D MMMM YYYY">{props.timestamp != '' ? props.timestamp : new Date().toLocaleDateString()}</Moment>}
        </p>
        <div className="py-2 mb-1">
          <label for="table-search" className="sr-only">
            Search
          </label>
          <div className="relative mt-1">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-gray-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              onChange={(e) => setFilterData(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-800 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5 placeholder:text-gray-900 "
              placeholder="Cari berdasarkan nama program / kegiatan / sub kegiatan"
            />
          </div>
        </div>
        <table className="w-full table-auto text-xs mb-3">
          <thead className="bg-primeColor1 text-white sticky top-0">
            <tr>
              <th className="border border-slate-500 " rowSpan={3}>
                Program / Kegiatan / Sub kegiatan
              </th>
              <th className="border border-slate-500 " rowSpan={3}>
                PPTK Kegiatan
              </th>
              <th className="border border-slate-500 " rowSpan={3}>
                PAGU
              </th>
              <th className="border border-slate-500 p-2" colSpan={3}>
                FISIK
              </th>
              <th className="border border-slate-500 p-2" colSpan={6}>
                KEUANGAN
              </th>
            </tr>
            <tr>
              <th className="border border-slate-500" rowSpan={2}>
                TARGET
              </th>
              <th className="border border-slate-500 p-1" rowSpan={2}>
                REALISASI
              </th>
              <th className="border border-slate-500 p-1" rowSpan={2}>
                DEVIASI
              </th>
              <th className="border border-slate-500" colSpan={2}>
                TARGET
              </th>
              <th className="border border-slate-500 p-1" colSpan={2}>
                REALISASI
              </th>
              <th className="border border-slate-500 p-1" colSpan={2}>
                DEVIASI
              </th>
            </tr>
            <tr className="">
              <th className="border border-slate-500 p-1">Rp</th>
              <th className="border border-slate-500 p-1">%</th>
              <th className="border border-slate-500 p-1">Rp</th>
              <th className="border border-slate-500 p-1">%</th>
              <th className="border border-slate-500 p-1">%</th>
            </tr>
          </thead>
          <tbody>
            { searchByNamaProgram().length > 0 ? searchByNamaProgram().map((items, keys) => {
              if (items.level === 1) {
                // Program
                return (
                  <tr
                    key={keys}
                    onClick={() => setColorTr(keys)}
                    className={items.isParent ? 'bg-primeColor2' : 'cursor-pointer'}
                  >
                    <td className="border border-slate-500 py-3 px-2 w-[25%]">
                      {' '}
                      <div dangerouslySetInnerHTML={{ __html: items.nama }}></div>
                    </td>
                    <td className="border border-slate-500 text-center font-bold p-2 w-[15%]">{items.pptk}</td>
                    <td className="border border-slate-500 text-right font-bold p-2 w-[10%]">
                      {numberFormatter(items.pagu)}
                    </td>
                    <td className="border border-slate-500 text-center p-2 font-bold"> {numberFormatter(items.persen_target_fisik)}</td>
                    <td className="border border-slate-500 text-center p-2 font-bold">{numberFormatter(items.persen_realisasi_fisik)}</td>
                    <td className="border border-slate-500 text-center p-2 font-bold">{numberFormatter(items.deviasi_fisik)}</td>
                    <td className="border border-slate-500 text-right p-2 w-[10%] font-bold">
                      {numberFormatter(items.rp_target_keuangan)}
                    </td>
                    <td className="border border-slate-500 text-center p-2 font-bold">{numberFormatter(items.persen_target_keuangan)}</td>
                    <td className="border border-slate-500 text-right p-2 font-bold">
                      {numberFormatter(items.rp_target_keuangan)}
                    </td>
                    <td className="border border-slate-500 text-center p-2 font-bold">{numberFormatter(items.persen_realisasi_keuangan)}</td>
                    <td className="border border-slate-500 text-center p-2 font-bold">{numberFormatter(items.deviasi_keuangan)}</td>
                  </tr>
                ) 
              }
              if (items.level === 2) {
                // kegiatan
                return (
                  <tr key={keys} onClick={() => setColorTr(keys)} className={'bg-secondaryColor2 cursor-pointer'}>
                    <td className="border border-slate-500 py-3 px-5 w-[25%]">
                      <div dangerouslySetInnerHTML={{ __html: items.nama }}></div>
                    </td>
                    <td className="border border-slate-500 text-center font-bold px-2 py-2 w-[15%]">{items.pptk}</td>
                    <td className="border border-slate-500 text-right font-bold px-2 py-2 w-[10%]">
                      {numberFormatter(items.pagu)}
                    </td>
                    <td className="border border-slate-500 text-center px-2 py-2 font-bold">
                      {' '}
                      {numberFormatter(items.persen_target_fisik)}
                    </td>
                    <td className="border border-slate-500 text-center px-2 py-2 font-bold">
                      {items.persen_realisasi_fisik}
                    </td>
                    <td className="border border-slate-500 text-center px-2 py-2 font-bold">{items.deviasi_fisik}</td>
                    <td className="border border-slate-500 text-right px-2 py-2 w-[10%] font-bold">
                      {numberFormatter(items.rp_target_keuangan)}
                    </td>
                    <td className="border border-slate-500 text-center px-2 py-2 font-bold">
                      {numberFormatter(items.persen_target_keuangan)}
                    </td>
                    <td className="border border-slate-500 text-right px-2 py-2 font-bold">
                      {numberFormatter(items.rp_realisasi_keuangan)}
                    </td>
                    <td className="border border-slate-500 text-center px-2 py-2 font-bold">
                      {numberFormatter(items.persen_realisasi_keuangan)}
                    </td>
                    <td className="border border-slate-500 text-center px-2 py-2 font-bold">{numberFormatter(items.deviasi_keuangan)}</td>
                  </tr>
                );
              }
              if (items.level === 3) {
                // Sub Kegiatan
                return (
                  <tr key={keys} onClick={() => setColorTr(keys)} className={'cursor-pointer'}>
                    <td className="border border-slate-500 py-3 px-8 w-[25%]">
                      {' '}
                      <div dangerouslySetInnerHTML={{ __html: items.nama }}></div>
                    </td>
                    <td className="border border-slate-500 text-center font-bold px-2 py-2 w-[15%]">{items.pptk}</td>
                    <td className="border border-slate-500 text-right font-bold px-2 py-2 w-[10%]">
                      {numberFormatter(items.pagu)}
                    </td>
                    <td className="border border-slate-500 text-center px-2 py-2 font-bold">
                      {' '}
                      {numberFormatter(items.persen_target_fisik)}
                    </td>
                    <td className="border border-slate-500 text-center px-2 py-2 font-bold">
                      {numberFormatter(items.persen_realisasi_fisik)}
                    </td>
                    <td className="border border-slate-500 text-center px-2 py-2 font-bold">{numberFormatter(items.deviasi_fisik)}</td>
                    <td className="border border-slate-500 text-right px-2 py-2 w-[10%] font-bold">
                      {numberFormatter(items.rp_target_keuangan)}
                    </td>
                    <td className="border border-slate-500 text-center px-2 py-2 font-bold">
                      {numberFormatter(items.persen_target_keuangan)}
                    </td>
                    <td className="border border-slate-500 text-right px-2 py-2 font-bold">
                      {numberFormatter(items.rp_realisasi_keuangan)}
                    </td>
                    <td className="border border-slate-500 text-center px-2 py-2 font-bold">
                      {numberFormatter(items.persen_realisasi_keuangan)}
                    </td>
                    <td className="border border-slate-500 text-center px-2 py-2 font-bold">{numberFormatter(items.deviasi_keuangan)}</td>
                  </tr>
                );
              }
            }) : <tr>
              <td colSpan='11' className="border border-slate-500 text-center font-bold py-3 px-8"> No Data </td>
            </tr> }
          </tbody>
        </table>
      </div>  
      <BackButton backLink={`/tahun/${yearconfig}/simbangda/detail-belanja-opd`} />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  dataOpdSeluruhnya: state.homeState.relisasiPerOpd.data,
  dataOpd: state.homeState.simbangdaKegiatanOpd.data,
  pencapaianOpd: state.homeState.simbangdaKegiatanOpd.pencapaian_opd,
  loading: state.homeState.simbangdaKegiatanOpd.loadingGet,
  error: state.homeState.simbangdaKegiatanOpd.errorGet,
  timestamp: state.homeState.simbangdaKegiatanOpd.timestamp,
  grafikData: state.homeState.grafikSimbangda.data,
});

const mapDispatchToProps = {
  getRealisasiKegiatanOPD,
  getGrafikSimbangda,
  getRealisasiBelanjaPerOPD
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(DetailKegiatanOpd);
