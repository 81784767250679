import React, { useEffect, memo, useState } from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import Layout from '../../../components/Layout';
import { useParams } from 'react-router-dom';
import { getDataIkpd_act } from "../../../../redux/ducks/ikp/actions"
import BackButton from '../../../components/BackButton';

const tahun = [2021,2022,2023,2024,2025,2026]
const DataIndikatorKinerjaProgram = (props) => {
    const { urusanId, urusanName, yearconfig } = useParams();
    const initBreadcrumbs = [
        {
          title: 'Home',
          isActive: true,
          goTo: '/tahun/'+yearconfig,
        },
        {
          title: `Indikator Kinerja Program`,
          isActive: true,
          goTo: `/tahun/${yearconfig}/ikp/layanan`,
        },
    ];
    const [breadCrumbsData, setBreadCrumbsData] = useState();
    useEffect(() => {
        setBreadCrumbsData([...initBreadcrumbs, {
            title: urusanName,
            isActive: false,
            goTo: ''
        }])
    },[])
    useEffect(() => {
        props.getDataIkpd(urusanId)
    },[])
    return (
        <Layout
            loading={props.dataIkp.loadingGet}
            breadCrumbsData={breadCrumbsData}
        >
          <div
              className="bg-white rounded-lg drop-shadow-2xl py-5 px-2 w-full"
          >
              <div className="flex-grow bg-white rounded-xl py-2 relative">
                  <h3 className="text-sm text-secondaryColor1 text-center">Indikator Kinerja Program</h3>
                  <h1 className="text-lg font-bold text-primeColor1 text-center">{urusanName}</h1>
                  <hr className="mb-3" />
              </div>
              
              <div className="w-full sm:overflow-visible overflow-x-auto flex flex-col justify-center">
                    <table className="w-full table-auto text-xs mb-4">
                        <thead className="bg-primeColor1 text-white sticky top-0">
                            <tr>
                                <th className="border border-slate-500  w-[1%]" rowSpan={3}>
                                    No
                                </th>
                                <th className="border border-slate-500 md:w-1/4" rowSpan={3}>
                                    Indikator
                                </th>
                                <th className="border border-slate-500" rowSpan={3}>
                                    Satuan
                                </th>
                                <th className="border border-slate-500" rowSpan={3}>
                                    Kondisi Awal
                                </th>
                                <th className="border border-slate-500" colSpan={tahun.length * 2}>
                                    Tahun
                                </th>
                                
                                <th className="border border-slate-500" rowSpan={3}>
                                    Kondisi Akhir
                                </th>
                                <th className="border border-slate-500 md:w-1/12" rowSpan={3}>
                                    Perangkat Daerah
                                </th>
                            </tr>
                            <tr>
                                {
                                    tahun.map((item) => {
                                        return (
                                            <th className="border border-slate-500" colSpan={2}>{item}</th>
                                        )
                                    })
                                }
                            </tr>
                            <tr>
                                {
                                    tahun.map((item) => {
                                        return (
                                            <>
                                                <th className="border border-slate-500">
                                                    <span className="md:block hidden">Target</span>
                                                    <span className="md:hidden block">T</span>
                                                </th>
                                                <th className="border border-slate-500">
                                                    <span className="md:block hidden">Capaian</span>
                                                    <span className="md:hidden block">C</span>
                                                </th>
                                            </>
                                        )
                                    })
                                }
                            </tr>
                            
                        </thead>
                        <tbody>
                            {
                                props.dataIkp ? props.dataIkp.data.map((item, keys) => {
                                    const indikator = renderIndikator(item.indikator)
                                    const program = (
                                        <>
                                            <tr key={keys}>
                                                <td className="border border-slate-500 font-bold py-2 px-5 bg-primeColor2" colSpan={18}>{item.program}</td>
                                            </tr>
                                            {
                                                indikator
                                            }
                                        </>
                                    )
                                    return (program)
                                }) : 
                                <tr>
                                    <td className="border border-slate-500 py-2 px-5 bg-green-200" colSpan={18}>Loading...</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <span className="text-red-400 text-xs font-bold">
                        Notes : <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; *) Data triwulan IV
                    </span>
                </div>
          </div>
          <BackButton backLink={`/tahun/${yearconfig}/ikp/layanan`} />
        </Layout>
    )
}

const renderIndikator = (list) => {
    return list?.map((val, keys) => {
        return (
            <tr className='odd:bg-secondaryColor2' key={keys}>
                <td className="border border-slate-500 p-2 text-center">{keys+1}</td>
                <td className="border border-slate-500 p-2">{val.indikator}</td>
                <td className="border border-slate-500 p-2 text-center">{val.satuan}</td>
                <td className="border border-slate-500 p-2">{val.kondisi_awal_rpjmd_2021}</td>
                {
                    tahun.map((item) => {
                        return (
                            <>
                                <td className="border border-slate-500 p-2 text-center">{val[`target_${item}`]}</td>
                                <td className="border border-slate-500 p-2 text-center">{val[`capaian_${item}`]}</td>
                            </>
                        )
                    })
                }
                <td className="border border-slate-500 p-2">{val.kondisi_akhir_rpjmd}</td>
                <td className="border border-slate-500 p-2">{val.perangkat_daerah}</td>
            </tr>
        )
    })
}

const mapStateToProps = (state) => ({
    dataIkp: state.ikpState.dataIkp
})

const mapDispatchToProps = {
    getDataIkpd: getDataIkpd_act
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, memo)(DataIndikatorKinerjaProgram)