import React, { useEffect, memo, useState } from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import Layout from '../../../components/Layout';
import { getListOPDPerjanjianKinerja_act } from "../../../../redux/ducks/cpk/actions"
import { NavLink, useParams } from 'react-router-dom';
import BackButton from '../../../components/BackButton';

const ListOpd_CapaianPK = (props) => {
    const { yearconfig } = useParams()
    useEffect(() => {
      props.getListOPDPerjanjianKinerja()
    },[])
    const breadCrumbsData = [
        {
          title: 'Home',
          isActive: true,
          goTo: '/tahun/'+yearconfig,
        },
        {
          title: `Capaian Perjanjian Kinerja Esselon II`,
          isActive: false,
          goTo: '',
        },
    ];
    const [searchFilter, setSearchFilter] = useState("")
    const filteredBySearch = () => {
        if (searchFilter === '') {
          return props.listOpd.data;
        } else return props.listOpd.data.filter((items) => items.nm_opd.toLowerCase().includes(searchFilter.toLowerCase()));
    }
  return (
    <Layout
      loading={false}
      breadCrumbsData={breadCrumbsData}
    >
      <div
        className="bg-white rounded-lg drop-shadow-2xl py-5 px-2 w-full"
      >
          <div className="flex-grow bg-white rounded-xl py-2 relative">
            <h1 className="text-lg font-bold text-center">Capaian Perjanjian Kinerja Esselon II</h1>
            <h1 className="mb-1 text-sm text-gray-400 text-center">
              pilih OPD di bawah untuk melihat Capaian Perjanjian Kinerja Esselon II
            </h1>
            <hr className="mb-3" />
          </div>
          <div className="flex flex-col lg:flex-row xl:flex-row justify-center mb-3">
              <div className="py-2 w-full lg:w-[30%] xl:w-[30%]">
                <label for="table-search" className="sr-only">
                  Search
                </label>
                <div className="relative mt-1">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      className="w-5 h-5 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <input
                    type="text"
                    onChange={(e) => setSearchFilter(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-800 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5 placeholder:text-gray-900 "
                    placeholder="Cari berdasarkan nama OPD"
                  />
                </div>
              </div>
          </div>
          <div className="w-full grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-1">
              {
                  filteredBySearch().map((val,keys) => {
                    if(val.id_opd > 2){
                      return (
                            <div>
                              <NavLink to={`/tahun/${yearconfig}/cpkess/data-capaian/${val.id_opd}/${val.nm_opd}`} 
                                className="cursor-pointer flex flex-row gap-3 p-2 group rounded-xl group hover:bg-white hover:text-primeColor2 bg-primeColor2 text-primeColor1 hover:border-primeColor2 border-white border-2 h-16 overflow-hidden sm:items-center">
                                  <div className="flex items-center justify-center p-1">
                                      <span 
                                          className="rounded-full p-1.5 group-hover:bg-primeColor2 group-hover:text-white bg-white text-primeColor1">
                                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
                                          </svg>

                                      </span>
                                  </div>
                                  <p className="text-[1em] leading-none">
                                    {val.nm_opd}
                                  </p>
                              </NavLink>
                            </div>
                          )
                        }
                        else return ""
                      })
              }
          </div>
      </div>
      <BackButton backLink={'/tahun/'+yearconfig} />
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  listOpd: state.pkState.listOPD
})

const mapDispatchToProps = {
    getListOPDPerjanjianKinerja: getListOPDPerjanjianKinerja_act
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect,memo)(ListOpd_CapaianPK)