import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const YearInit = () => {
    const navigate = useNavigate()
    useEffect(() => {
        const year = "2025" //new Date().getFullYear()
        navigate(`/tahun/${year}`)
    },[])
    return (
        <></>
    )
}

export default YearInit